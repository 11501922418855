export const Holiday = {
  // Data sourse: https://data.gov.tw/dataset/14718
  holidayList: [
    // 2021
    '20210101',
    '20210210',
    '20210211',
    '20210212',
    '20210213',
    '20210214',
    '20210215',
    '20210216',
    '20210228',
    '20210301',
    '20210402',
    '20210404',
    '20210405',
    '20210614',
    '20210920',
    '20210921',
    '20211010',
    '20211011',
    '20211231',
    // 2022
    '20220101',
    '20220131',
    '20220201',
    '20220202',
    '20220203',
    '20220204',
    '20220228',
    '20220404',
    '20220405',
    '20220603',
    '20220909',
    '20220910',
    '20221010'
  ]
}
